/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { func, object, string } from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import OfferGatewayBannerTall from './OfferGatewayBannerTall';
import noop from '../../../../../helpers/noop';

const useStyles = makeStyles(() => ({
    defaultCSS: {
        margin: '2rem auto',
        maxWidth: '1400px',
    },
    withGridSystem: {
        margin: '0 auto',
        maxWidth: '1400px',
        width: '100%',
    },
}));

// Sync with UniversalHomepageComponent.js to make similar banners styling.

const OfferGatewayBannerContainer = ({
    blockKey, blockObj, salesforceResponse, setShowSkeleton,
}) => {
    const classes = useStyles();
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));

    useEffect(() => {
        if (!blockObj || !salesforceResponse?.content) {
            setShowSkeleton(false);
        }
    }, []);

    const OfferGatewayBannerContainerStyle = ffHasHomepageGridStructure ? classes.withGridSystem : classes.defaultCSS;

    const blockData = salesforceResponse?.content ? salesforceResponse.content : blockObj;// blockObj is used for fallbackUI

    const renderBanner = () => {
        switch (blockKey) {
            case 'offer_gatway_banner_tall':
                return (
                    <OfferGatewayBannerTall
                        blockObj={blockData}
                        blockKey={blockKey}
                        salesforceResponse={salesforceResponse}
                        setShowSkeleton={setShowSkeleton}
                    />
                );

            default:
                return null;
        }
    };

    if (blockData) {
        return (
            <div className={OfferGatewayBannerContainerStyle} data-testid="homepage_offer_gateway_component">
                <div>
                    <style>{'[data-pagetype="homepage"]>div[data-component="universalHomepageComponent"]'}</style>
                    {renderBanner()}
                </div>
            </div>
        );
    }

    return null;
};

OfferGatewayBannerContainer.propTypes = {
    salesforceResponse: object,
    blockKey: string,
    setShowSkeleton: func,
    blockObj: object,
};

OfferGatewayBannerContainer.defaultProps = {
    salesforceResponse: {},
    blockKey: '',
    blockObj: {},
    setShowSkeleton: noop,
};

export default OfferGatewayBannerContainer;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, {
    useEffect, useRef, useState,
} from 'react';
import {
    any, object, func, string,
} from 'prop-types';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';

const ConversationalUIChatBot = ({
    deviceType, brand, browser, operatingSystem, brandTheme, pageType,
}) => {
    const iframeRef = useRef(null);
    const pageTypeRef = useRef(pageType);
    const [isIframeMaximized, setIsIframeMaximized] = useState(false);
    const [hideChat, setHideChat] = useState(false);

    const isMobile = deviceType !== 'desktop';
    const [isIframeLoaded, setIsIframeLoaded] = useState(true);
    const trustedOrigin = `${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}`;

    useEffect(() => {
        pageTypeRef.current = pageType;
    }, [pageType]);

    useEffect(() => {
        if (isIframeMaximized) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isIframeMaximized]);

    useEffect(() => {
        const handleMessage = (event) => {
            try {
                if (event.data.type === 'conv-ui-session-info') {
                    const url = window.location.href;
                    const auth = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.auth);
                    const profile = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.profile);
                    const enterprise = JSON.parse(JSON.parse(sessionStorage.getItem('persist:member'))?.enterpriseId);
                    const browseruuid = localStorage?.getItem('browserUUID') || '';
                    event.source.postMessage({
                        type: 'session-storage-data',
                        data: {
                            url,
                            brand,
                            browser,
                            deviceType,
                            operatingSystem,
                            brandTheme,
                            auth,
                            profile,
                            enterprise,
                            browseruuid,
                        },
                    }, `${trustedOrigin}/chat`);
                }
                if (event.data.type === 'search-suggestion-submitted') {
                    setHideChat(false);
                    const iframe = document?.querySelector(`iframe[src="${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}/chat"]`);
                    if (iframe && iframe?.contentWindow) {
                        iframe.contentWindow?.postMessage(
                            { type: 'update-chat-message-search', data: event.data },
                            mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST'),
                        );
                    }
                }
                if (event.data.type === 'end-chat-hide-button') {
                    setHideChat(true);
                }
                if (event.data.type === 'footer-suggestion-submitted') {
                    const iframe = document?.querySelector(`iframe[src="${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}/chat"]`);
                    if (iframe && iframe?.contentWindow) {
                        iframe.contentWindow.postMessage(
                            { type: 'update-chat-message-footer', data: event.data },
                            mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST'),
                        );
                    }
                }
                if (event.data.type === 'get-page-info') {
                    const iframe = document?.querySelector(`iframe[src="${mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST')}/chat"]`);
                    if (iframe && iframe?.contentWindow) {
                        iframe.contentWindow.postMessage(
                            { type: 'update-page-info', data: pageTypeRef.current },
                            mbpUtil.getEnv('APP_CONVERSATIONAL_UI_HOST'),
                        );
                    }
                }
                if (event.data.type === 'chat-iframe-maximised') {
                    if (isMobile) {
                        setIsIframeMaximized(() => true);
                        iframeRef.current.style.height = '105vh';
                        iframeRef.current.style.width = '100vw';
                        iframeRef.current.style.right = '0px';
                        // dynamic top for iframe based on headerElement height
                        iframeRef.current.style.top = '12%';
                    } else {
                        iframeRef.current.style.height = '725px';
                        iframeRef.current.style.width = '400px';
                        iframeRef.current.style.bottom = '-9%';
                    }
                }
                if (event.data.type === 'chat-iframe-minimised') {
                    setIsIframeMaximized(() => false);
                    iframeRef.current.style.height = '80px';
                    iframeRef.current.style.width = '80px';
                    iframeRef.current.style.top = '';
                    iframeRef.current.style.bottom = '10px';
                }
            } catch (error) {
                mbpLogger.logError({
                    appName: process.env.npm_package_name,
                    errorMessage: error,
                    message: `FloraChat: Could not post value - ERROR: ${error}`,
                });
            }
        };
        if (isIframeLoaded) {
            window?.addEventListener('message', handleMessage);
        }
        return () => {
            window?.removeEventListener('message', handleMessage);
        };
    }, [isIframeLoaded, trustedOrigin]);

    const handleIframeLoad = () => setIsIframeLoaded(true);
    const handleIframeError = () => setIsIframeLoaded(false);

    return isIframeLoaded ? (
        <iframe
            id="18fchatbot"
            ref={iframeRef}
            title="18fchatbot"
            className="test"
            allowFullScreen
            style={{
                overflow: 'hidden',
                boxShadow: 'none',
                border: 'none',
                position: 'fixed',
                zIndex: 2243000,
                bottom: '10px',
                right: '0%',
                width: '80px',
                height: '80px',
                // eslint-disable-next-line no-nested-ternary
                visibility: isMobile ? (hideChat ? 'hidden' : 'visible') : 'visible',
            }}
            src={`${trustedOrigin}/chat`}
            onLoad={handleIframeLoad}
            onError={handleIframeError}
        />
    ) : null;
};

ConversationalUIChatBot.propTypes = {
    deviceType: string.isRequired,
    brand: object.isRequired,
    browser: func.isRequired,
    operatingSystem: func.isRequired,
    brandTheme: any.isRequired,
    pageType: string.isRequired,
};

export default ConversationalUIChatBot;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, bool, func, object, string,
} from 'prop-types';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core';
import StyledButton from '../../../../EnterpriseDesignSystem/Button/StyledButton';
import { setDialogOpen, setDialogType, setOfferGatewayCTALabel } from '../../../../../../state/ducks/App/App-Actions';
import replaceSpecialChar from '../../../../../helpers/replaceSpecialChar';
import noop from '../../../../../helpers/noop';

export default function OfferGatewayButton({
    ctaArray, buttonContainer, clickCallback, isAuthFlow,
}) {
    const dispatch = useDispatch();

    const handleClick = (event, item) => {
        // avoid launching modal if user is in auth flow
        if (item.launch_modal && !isAuthFlow) {
            event.stopPropagation();
            event.preventDefault();
            dispatch(setDialogOpen(true));
            dispatch(setDialogType('offerGateway'));
            dispatch(setOfferGatewayCTALabel(item.tracking.tracking_event_label));
        }
        clickCallback();
    };

    const renderHelperText = (item) => {
        if (item.is_helper_text) {
            return <p className="text2"> {replaceSpecialChar(item.helper_text, true)}</p>;
        }
        return <></>;
    };

    return ctaArray?.map((item) => (
        <Button
            item={item}
            handleClick={handleClick}
            renderHelperText={renderHelperText}
            buttonContainer={buttonContainer}
        />
    ));
}

OfferGatewayButton.propTypes = {
    ctaArray: array.isRequired,
    buttonContainer: string.isRequired,
    isOfferUnlocked: bool.isRequired,
    clickCallback: func,
    isAuthFlow: bool,
};

OfferGatewayButton.defaultProps = {
    clickCallback: noop,
    isAuthFlow: false,
};

const useStyles = makeStyles((theme) => ({
    buttonContainer: (styles) => ({
        marginTop: '12px',
        alignItems: 'end',
        '& button, a': {
            padding: 0,
            '& span': {
                padding: styles.padding,
            },
            '& span[class*="button_icon"]': {
                backgroundColor: styles.iconBGColor,
                color: styles.iconColor,
                padding: styles.iconPadding,
                margin: 0,
            },
        },
    }),
}));

const Button = ({
    buttonContainer, item, handleClick, renderHelperText,
}) => {
    const classes = useStyles({
        padding: item?.padding,
        iconPadding: item?.icon_padding,
        iconColor: item?.icon_color?.color,
        iconBGColor: item?.icon_bg_color?.color,
    });

    return (
        <div className={`${buttonContainer} ${classes.buttonContainer}`}>
            {renderHelperText(item)}
            <StyledButton
                label={item?.cta_button?.link?.title}
                link={item?.cta_button?.link?.href}
                variant={item?.cta_button?.variant}
                size={item?.cta_button?.size}
                attributes={item?.cta_button?.html_attributes}
                handleOnClick={(event) => handleClick(event, item?.cta_button)}
                icon={item?.icon}
            />
        </div>
    );
};

Button.propTypes = {
    buttonContainer: string.isRequired,
    renderHelperText: func,
    item: object.isRequired,
    handleClick: func,
};

Button.defaultProps = {
    renderHelperText: noop,
    handleClick: noop,
};

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

import { getFeatureFlag, getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getSSRDeviceType } from '../../../../state/ducks/App/App-Selectors';

import MobileGraphqlHeader from './MobileGraphqlHeader';

const MobileHeaderContainer = ({ isEyeBrowShow }) => {
    const ffWhichCheckoutVariation = useSelector(getFeatureFlag('which-checkout-variation'));
    const ffIsHideMobileHeaderOnScrollEnabled = useSelector(getFeatureFlag('is-hide-mobile-header-on-scroll-enabled'));
    const ffIsSearchUiInMobileMenuEnabled = useSelector(getFeatureFlag('is-search-ui-in-mobile-menu-enabled'));
    const brand = useSelector(getBrand);
    const presentationFamily = useSelector(getPresentationFamily);
    const deviceType = useSelector(getSSRDeviceType);

    return (
        <MobileGraphqlHeader
            isEyeBrowShow={isEyeBrowShow}
            brand={brand}
            presentationFamily={presentationFamily}
            ffWhichCheckoutVariation={ffWhichCheckoutVariation}
            ffIsHideMobileHeaderOnScrollEnabled={ffIsHideMobileHeaderOnScrollEnabled}
            ffIsSearchUiInMobileMenuEnabled={ffIsSearchUiInMobileMenuEnabled}
            deviceType={deviceType}
        />
    );
};

MobileHeaderContainer.propTypes = {
    isEyeBrowShow: bool.isRequired,
};

export default MobileHeaderContainer;
